import { useEffect } from 'react';
import '../../styles/global.css'; // Global styling import
import './news.css'; // Ensure this file exists and contains styles


const Mission = () => {
    useEffect(() => {
        // Adding IntersectionObserver for animations (fade-up, fade-in, slide-in-left)
        const elements = document.querySelectorAll('.fade-up, .fade-in, .slide-in-left');
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('in-view');
                }
            });
        });

        elements.forEach(element => {
            observer.observe(element);
        });
    }, []);

    return (
        <div className="section section-news">
            <div className="container-news">
                <div className="grid-2col-news grid-headline">
                    <h2 className="nomargin title">
                        Latest News    
                        <span className="text-gold"></span>
                        <br></br>
                    </h2>
                    <a href="/news" className="btn w-button">See All News</a>
                </div>
                <div className="news-collection">
                    <div className="news-list">
                        <div className="news-item">
                            <a className="grid-2col-news grid-news w-inline-block-news" href="https://www.bloomberg.com/energy">
                                <img loading="eager" src="/news/oil.jpg" alt="Oil Picture" className="news-img fade-in" />
                                <div className="news-text">
                                    <div className="news-title fade-in ">
                                        <h6 className="h-tag ">FEATURED</h6>
                                        <h3 className="news-title-h">International Oil prices</h3>
                                    </div>
                                    <div className="flex-2col fade-in">
                                        <img loading="lazy" alt='Calendar Image' src="/calendar.svg" className="news-calendar" />
                                        <h6 className="news-post-info">September 16, 2024</h6>
                                    </div>
                                    <div className="gold-arrow blog-arrow" style={{ backgroundImage: 'url("/arrow.svg")' }}></div>
                                </div>
                            </a>
                        </div>
                        <div className="news-item">
                            <a className="grid-2col-news grid-news w-inline-block-news" href="https://www.supplychaindive.com/">
                                <img loading="eager" src="/news/supply-chain.jpg" alt="Supply Chains" className="news-img fade-in" />
                                <div className="news-text">
                                    <div className="news-title fade-in ">
                                        <h6 className="h-tag ">Recent Update</h6>
                                        <h3 className="news-title-h">The push for reliable supply chains.</h3>
                                    </div>
                                    <div className="flex-2col fade-in">
                                        <img loading="lazy" alt='Calendar Image' src="/calendar.svg" className="news-calendar" />
                                        <h6 className="news-post-info">Aug 10, 2024</h6>
                                    </div>
                                    <div className="gold-arrow blog-arrow" style={{ backgroundImage: 'url("/arrow.svg")' }}></div>
                                </div>
                            </a>
                        </div>
                        <div className="news-item">
                            <a className="grid-2col-news grid-news w-inline-block-news" href="/">
                                <img loading="eager" src="/logo2.png" alt="Internal Tech Update" className="news-img fade-in" />
                                <div className="news-text">
                                    <div className="news-title fade-in ">
                                        <h6 className="h-tag ">Internal Tech Update</h6>
                                        <h3 className="news-title-h">Coming Soon</h3>
                                        <p className="news-description">We are currently busy at work, building the future of GLOTON's Trade Application. This will be the future of global trade for all our buyers and suppliers.</p>
                                    </div>  
                                    <div className="flex-2col fade-in">
                                        <img loading="lazy" alt="Calendar Image" src="/calendar.svg" className="news-calendar" />
                                        <h6 className="news-post-info">July 3, 2024</h6>
                                    </div>
                                    <div className="gold-arrow blog-arrow" style={{ backgroundImage: 'url("/arrow.svg")' }}></div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Mission;
