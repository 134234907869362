import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Import global styles
import './styles/global.css';  // Ensure the correct path to your global CSS file

// Import components and pages
import Home from './pages/Home/Home';  // Assuming you moved your homepage content to a separate file
// import About from './pages/About';  // Other pages (About, Contact, Services)
// import Contact from './pages/Contact';
// import Services from './pages/Services';

function App() {
  return (
    <Router>

      {/* Define Routes for different pages */}
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services" element={<Services />} /> */}
      </Routes>

      {/* Global components such as Footer */}
    </Router>
  );
}

export default App;