import "../../styles/global.css"
import "./footer.css"


const Footer = () => {
    const currentYear = new Date().getFullYear(); // Get the current year

    return (
        <div
            className="footer">
            <div className="footer-globe" style={{ backgroundImage: 'url("/static-background-globe.png")' }}>
            </div>
            <div className="container border-top">
                <div className="grid-2col-bottom grid-footer">
                    <a href="/" className="brand brand-footer w-nav-brand w--current">
                        <img src="/logo.png" alt="Logo 1" className="logo" />
                    </a>
                </div>
                <div className="grid-3col grid-footer">
                    <div className="footer-location">
                        <h6 className="text-gold">United Kingdom</h6>
                        <p className="text-gold">
                            <a href="mailto:info@gloton.co.uk">info@gloton.co.uk</a>
                            </p>
                        <p className="text-gold">
                            <a href="tel:+442033869440">+44 20 338 69440</a>
                            </p>
                        <p className="footer-p">Suite 28-03 Gherkin, 30 St Mary Axe, London, United Kingdom, EC3A 8BF</p>
                    </div>
                </div>
                <div className="footer-info">
                    <p>© {currentYear} GLOTON. All Rights Reserved</p> {/* Dynamic year */}
                    <div className="footer-links">  
                        <a
                            href="https://www.linkedin.com/company/gloton/about/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="social-icon w-inline-block-footer"
                        >
                            <img src="/Social/linkedin.svg" loading="lazy" alt="LinkedIn" />
                        </a>
                        <a
                            href="https://twitter.com/glotonTrade"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="social-icon w-inline-block-footer"
                        >   
                            <img src="/Social/twitter.svg" loading="lazy" alt="Twitter" />
                        </a>
                        <a
                            href="https://www.instagram.com/glotonTrade"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="social-icon w-inline-block-footer"
                        >
                            <img src="/Social/instagram.svg" loading="lazy" alt="Instagram" />
                        </a>
                        <a
                            href="https://www.facebook.com/glotonTrade"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="social-icon w-inline-block-footer"
                        >
                            <img src="/Social/facebook.svg" loading="lazy" alt="Facebook" />
                        </a>
                        <a
                            href="https://www.tiktok.com/@glotonTrade"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="social-icon w-inline-block-footer"
                        >
                            <img src="/Social/tiktok.svg" loading="lazy" alt="TikTok" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
