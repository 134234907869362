import Lottie from 'react-lottie';
import animationData from './gloton-load.json'; // Path to Lottie JSON file
import './loadingAnimation.css';

const LoadingWrapper = () => {
  return (
    <div className="loading-container">
      <Lottie 
        options={{
          animationData,
          loop: false,
        }} 
        height={800}
        width={800} // Adjust for devices
      />
    </div>
  );
};

export default LoadingWrapper;