import { useEffect, useState } from "react";
import { globeConfig } from "../../utils/config.globe";
import { World } from "../../utils/globe";

// Components
import WelcomeBlock from '../../components/WelcomeBlock/WelcomeBlock';
import Mission from '../../components/Mission/Mission';
import Navbar from '../../components/Navbar/Navbar';
import ScrollFeatures from '../../components/ScrollFeatures/ScrollFeature';
import News from '../../components/News/News';
import Footer from '../../components/Footer/Footer';
import './home.css';

import LoadingWrapper from '../../components/LoadingWrapper/LoadingWrapper';

function Home() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading content
    const timer = setTimeout(() => {
      setLoading(false);  
    }, 3000); // Simulate a delay (3 seconds here)

    return () => clearTimeout(timer); // Cleanup on component unmount
  }, []);

  useEffect(() => {
    if (!loading) { // Ensure the globe loads only after loading is complete
      if (!document.getElementById("globe-canvas")) {
        const container = document.querySelector("#scene-container");
        const world = new World(container as Element, undefined, globeConfig);
        world.start();
      }
    }
  }, [loading]); // Depend on the loading state

  if (loading) {
    return <LoadingWrapper />; // Show loading screen if still loading
  }

  return (
    <>
      <Navbar />
      {/* Fixed globe that stays in the background */}
      <div id="scene-container"></div>  {/* ID matches the CSS rule */}
      <WelcomeBlock />
      <Mission />
      <ScrollFeatures />
      <News />
      <Footer />
    </>
  );
}

export default Home;
