import * as THREE from 'three';
import threeGlobe from 'three-globe';

class Globe extends threeGlobe {
  constructor({
    waitForGlobeReady,
    animateIn,
  }: {
    waitForGlobeReady?: boolean;
    animateIn?: boolean;
  }) {
    super({ waitForGlobeReady, animateIn });

    // Load the globe texture using the image URL
    this.globeImageUrl('/globe.jpeg'); // Adjust this path as necessary
  }

  tick: (delta: number) => void = () => {};
}

export { Globe };
