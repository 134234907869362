"use client"; // Ensure this file is a client component

import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './Navbar.css';

gsap.registerPlugin(ScrollTrigger);

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navbarRef = useRef(null); // Ref for the navbar

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    let lastScrollY = window.scrollY;

    // Detect scroll direction and apply fade effect
    const updateNavbarVisibility = () => {
      if (window.scrollY > lastScrollY) {
        // Scrolling down: fade out the navbar
        gsap.to(navbarRef.current, { opacity: 0, duration: 0.2 });
      } else {
        // Scrolling up: fade in the navbar
        gsap.to(navbarRef.current, { opacity: 1, duration: 0.2 });
      }
      lastScrollY = window.scrollY;
    };

    window.addEventListener('scroll', updateNavbarVisibility);

    return () => {
      window.removeEventListener('scroll', updateNavbarVisibility);
    };
  }, []);

  return (
    <div ref={navbarRef} className="navbar">
      {/* Logo section */}
      <div className="logo-container">
        <a href='#home'>
        <img src="/logo.png" alt="Logo 1" className="logo" />
        </a>
      </div>
      
      {/* Hamburger icon - Need to make function.
      <div className={`hamburger ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <span>Home</span>
        <span>About us</span>
        <span>Services</span>   
        <span>Contact</span>   
        <span>Coming Soon</span>   
      </div> */}
      {/* Navigation links */}
      {/* <div className={`nav-links ${isOpen ? 'active' : ''}`}>
        <a href="#home" class="active">Home</a>        
        <a href="#about">About Us</a>
        <a href="#services">Services</a>
        <a href="#contact">Contact</a>
        <a href="#contact">Coming Soon</a>
      </div> */}
    </div>
  );
};

export default Navbar;
