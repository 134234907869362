import { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './WelcomeBlock.css';

gsap.registerPlugin(ScrollTrigger);

const WelcomeBlock = () => {
  const circleRef = useRef(null);
  const statsRef = useRef(null);
  const textRef = useRef(null);
  const subTextRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    // Function to dynamically set viewport height for mobile browsers
    const setViewportHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    // Set the viewport height initially
    setViewportHeight();

    // Adjust viewport height on resize and orientation change
    window.addEventListener('resize', setViewportHeight);
    window.addEventListener('orientationchange', setViewportHeight);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', setViewportHeight);
      window.removeEventListener('orientationchange', setViewportHeight);
    };
  }, []);

  useEffect(() => {
    // Initial page-load animation for heading, subtext, and button
    gsap.fromTo(
      [textRef.current, subTextRef.current, buttonRef.current],
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        ease: 'power2.out',
        stagger: 0,
      }
    );

    // Yellow Arc Expansion (scroll-triggered)
    const circle = circleRef.current;
    const radius = 280; // Radius for the circle
    const circumference = 2 * Math.PI * radius;

    // Set initial stroke properties (starting with the full circle hidden)
    circle.style.strokeDasharray = `${circumference} ${circumference}`;
    circle.style.strokeDashoffset = circumference; // Initially hide the entire yellow arc

    gsap.to(circle, {
      strokeDashoffset: 0, // Reveal the yellow stroke as user scrolls
      ease: 'none',
      scrollTrigger: {
        trigger: circle,
        start: 'top center',
        end: 'bottom center',
        scrub: true,
      },
    });

    // Statistics fade in (scroll-triggered)
    gsap.fromTo(
      statsRef.current.children,
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        ease: 'power2.out',
        stagger: 0.2,
        scrollTrigger: {
          trigger: statsRef.current,
          start: 'top bottom',
          scrub: true,
        },
      }
    );

    // Smooth fade-out and fade-in on scroll for text and button
    gsap.fromTo(
      [textRef.current, subTextRef.current, buttonRef.current],
      { opacity: 1, y: 0 }, // Initial state (fully visible)
      {
        opacity: 0,
        y: -50, // Moves slightly up as it fades out
        scrollTrigger: {
          trigger: '.globe-section', // Trigger based on the globe section
          start: 'top+=100 center', // When the top of the globe section is in view
          end: 'top+=300 center', // Adjust the end point to make sure the text fully fades out by the time the globe is visible
          scrub: true, // Smooth scrubbing
        },
      }
    );
  }, []);

  return (
    <div className="welcome-block">
      {/* Header Section */}
      <div className="header-section">
        <h1 ref={textRef}>
          A MAJOR SHIFT <br />
          <span>IN GLOBAL TRADE</span>
        </h1>
        <p ref={subTextRef}>
        GLOTON is a global trade management network, creating seamless and efficient trade solutions.
        </p>
        <button ref={buttonRef} onClick={() => window.location.href = 'mailto:info@gloton.co.uk'}>
          Contact us now
        </button>
      </div>

      {/* SVG Circle Animation */}
      <div className="globe-section">
        <svg className="globe-ring" width="600" height="600" viewBox="0 0 600 600">
          <circle
            cx="300"
            cy="300"
            r="280"
            fill="none" // No fill for the grey stroke
            stroke="grey" // Grey stroke for the outline
            strokeWidth="3" // Stroke thickness
          />
          <circle
            ref={circleRef}
            cx="300"
            cy="300"
            r="280"
            fill="none" // No fill for the yellow stroke
            stroke="yellow" // Yellow stroke
            strokeWidth="3" // Stroke thickness
            strokeDasharray="0 1760" // Initial state (hidden arc)
            strokeLinecap="round"
            transform="rotate(-90 300 300)" // Rotates the yellow arc to start at the top
          />
        </svg>
      </div>

      {/* KPIs Section */}
      <div className="kpis-box kpis-hero" ref={statsRef}>
        <div className="kpi-box kpi-box-1">
          <h2>1B+</h2>
          <h5 className='kpi-sub-h'>Suppliers</h5>
        </div>
        <div className="kpi-box kpi-box-2">
          <h2>10,000+</h2>
          <h5 className='kpi-sub-h'>Buyers</h5>
        </div>
        <div className="kpi-box kpi-box-3">
          <h2>5000+</h2>
          <h5 className='kpi-sub-h'>Analytics and Insights</h5>
        </div>      
      </div>
    </div>
  );
};

export default WelcomeBlock;
