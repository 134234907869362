export const globeConfig = {
  pointSize: 1,
  globeColor: "#062056",
  showAtmosphere: true,
  atmosphereColor: "#ffffff",
  atmosphereAltitude: 0.1,
  emissive: "#000000",
  emissiveIntensity: 0.1,
  shininess: 0.9,
  polygonColor: "rgba(255,255,255,0.7)",
  ambientLight: "#000000",
  directionalLeftLight: "#000000",
  directionalTopLight: "#ffffff",
  pointLight: "#ffffff",
  flightTime: 1000,
  flightLength: 0.9,
  rings: 1,
  maxRings: 3,
  initialPosition: { lat: 22.3193, lng: 114.1694 },
  autoRotate: true,
  autoRotateSpeed: 0.5,
};

export const flights = [
  {
    order: 1,
    from: "New Delhi",
    to: "Kuala Lumpur",
    startLat: 28.6139,
    startLng: 77.209,
    endLat: 3.139,
    endLng: 101.6869,
    arcAlt: 0.2,
    color: "#FFFFFF",
  },
  {
    order: 1,
    from: "Belo Horizonte",
    to: "Nairóbi",
    startLat: -19.885592,
    startLng: -43.951191,
    endLat: -1.303396,
    endLng: 36.852443,
    arcAlt: 0.5,
    color: "#FFFFFF",
  },
  {
    order: 2,
    from: "Singapore",
    to: "Tokyo",
    startLat: 1.3521,
    startLng: 103.8198,
    endLat: 35.6762,
    endLng: 139.6503,
    arcAlt: 0.2,
    color: "#FFFFFF",
  },
  {
    order: 2,
    from: "Brasília",
    to: "Las Vegas",
    startLat: -15.785493,
    startLng: -47.909029,
    endLat: 36.162809,
    endLng: -115.119411,
    arcAlt: 0.3,
    color: "#FFFFFF",
  },
  {
    order: 3,
    from: "Sydney",
    to: "Hong Kong",
    startLat: -33.8688,
    startLng: 151.2093,
    endLat: 22.3193,
    endLng: 114.1694,
    arcAlt: 0.3,
    color: "#FFFFFF",
  },
  {
    order: 3,
    from: "Jakarta",
    to: "London",
    startLat: -6.2088,
    startLng: 106.8456,
    endLat: 51.5072,
    endLng: -0.1276,
    arcAlt: 0.3,
    color: "#FFFFFF",
  },
  {
    order: 4,
    from: "Buenos Aires",
    to: "Hong Kong",
    startLat: -34.6037,
    startLng: -58.3816,
    endLat: 22.3193,
    endLng: 114.1694,
    arcAlt: 0.7,
    color: "#FFFFFF",
  },
  {
    order: 4,
    from: "London",
    to: "Paris",
    startLat: 51.5072,
    startLng: -0.1276,
    endLat: 48.8566,
    endLng: -2.3522,
    arcAlt: 0.1,
    color: "#FFFFFF",
  },
  {
    order: 5,
    from: "Manila",
    to: "London",
    startLat: 14.5995,
    startLng: 120.9842,
    endLat: 51.5072,
    endLng: -0.1276,
    arcAlt: 0.3,
    color: "#FFFFFF",
  },
  {
    order: 5,
    from: "Singapore",
    to: "Sydney",
    startLat: 1.3521,
    startLng: 103.8198,
    endLat: -33.8688,
    endLng: 151.2093,
    arcAlt: 0.2,
    color: "#FFFFFF",
  },
  {
    order: 6,
    from: "Lusaka",
    to: "Amazônia",
    startLat: -15.432563,
    startLng: 28.315853,
    endLat: 1.094136,
    endLng: -63.34546,
    arcAlt: 0.7,
    color: "#FFFFFF",
  },
  {
    order: 6,
    from: "Seoul",
    to: "Tokyo",
    startLat: 37.5665,
    startLng: 126.978,
    endLat: 35.6762,
    endLng: 139.6503,
    arcAlt: 0.1,
    color: "#FFFFFF",
  },
  {
    order: 6,
    from: "Hong Kong",
    to: "London",
    startLat: 22.3193,
    startLng: 114.1694,
    endLat: 51.5072,
    endLng: -0.1276,
    arcAlt: 0.3,
    color: "#FFFFFF",
  },
  {
    order: 7,
    from: "Belo Horizonte",
    to: "Cuiabá",
    startLat: -19.885592,
    startLng: -43.951191,
    endLat: -15.595412,
    endLng: -56.05918,
    arcAlt: 0.1,
    color: "#FFFFFF",
  },
  {
    order: 8,
    from: "Luanda",
    to: "Cidade do Cabo",
    startLat: -8.833221,
    startLng: 13.264837,
    endLat: -33.936138,
    endLng: 18.436529,
    arcAlt: 0.2,
    color: "#FFFFFF",
  },
  {
    order: 8,
    from: "Singapore",
    to: "New York City",
    startLat: 1.3521,
    startLng: 103.8198,
    endLat: 40.7128,
    endLng: -74.006,
    arcAlt: 0.5,
    color: "#FFFFFF",
  },
  {
    order: 9,
    from: "Singapore",
    to: "Buenos Aires",
    startLat: 1.3521,
    startLng: 103.8198,
    endLat: -34.6037,
    endLng: -58.3816,
    arcAlt: 0.5,
    color: "#FFFFFF",
  },
  {
    order: 10,
    from: "Jakarta",
    to: "Amsterdam",
    startLat: -6.2088,
    startLng: 106.8456,
    endLat: 52.3676,
    endLng: 4.9041,
    arcAlt: 0.3,
    color: "#FFFFFF",
  },
  {
    order: 11,
    from: "Jakarta",
    to: "Shanghai",
    startLat: -6.2088,
    startLng: 106.8456,
    endLat: 31.2304,
    endLng: 121.4737,
    arcAlt: 0.2,
    color: "#FFFFFF",
  },
  {
    order: 11,
    from: "Hong Kong",
    to: "Singapore",
    startLat: 22.3193,
    startLng: 114.1694,
    endLat: 1.3521,
    endLng: 103.8198,
    arcAlt: 0.2,
    color: "#FFFFFF",
  },
  {
    order: 12,
    from: "Los Angeles",
    to: "San Francisco",
    startLat: 34.0522,
    startLng: -118.2437,
    endLat: 37.7749,
    endLng: -122.4194,
    arcAlt: 0.1,
    color: "#FFFFFF",
  },
  {
    order: 12,
    from: "Tokyo",
    to: "Hong Kong",
    startLat: 35.6762,
    startLng: 139.6503,
    endLat: 22.3193,
    endLng: 114.1694,
    arcAlt: 0.2,
    color: "#FFFFFF",
  },
  {
    order: 13,
    from: "Berlin",
    to: "Hong Kong",
    startLat: 52.52,
    startLng: 13.405,
    endLat: 22.3193,
    endLng: 114.1694,
    arcAlt: 0.3,
    color: "#FFFFFF",
  },
  {
    order: 13,
    from: "Kano",
    to: "Tokyo",
    startLat: 11.986597,
    startLng: 8.571831,
    endLat: 35.6762,
    endLng: 139.6503,
    arcAlt: 0.3,
    color: "#FFFFFF",
  },
  {
    order: 13,
    from: "Rio de Janeiro",
    to: "Buenos Aires",
    startLat: -22.9068,
    startLng: -43.1729,
    endLat: -34.6037,
    endLng: -58.3816,
    arcAlt: 0.1,
    color: "#FFFFFF",
  },
  {
    order: 14,
    from: "Cidade do Cabo",
    to: "Meca",
    startLat: -33.936138,
    startLng: 18.436529,
    endLat: 21.395643,
    endLng: 39.883798,
    arcAlt: 0.3,
    color: "#FFFFFF",
  },
];
